.pageContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: aliceblue;
  height: 100%;
  padding: 1rem 1rem 3rem;
  max-width: 47rem;
  width: 100%;
}

.listContainer {
}

.listContainer a {
  font-size: 1.3rem;
  line-height: 1.5;
  font-weight: 300;
}

.headingBox {
  padding: 3rem 10rem;
  /* background-color: rgba(80, 48, 80, 0.5); */
}

.tableContainer {
  width: 100%;
}

.pageContainer h4 {
  margin: 3rem 0 0;
}

.tableContainer h6 {
  margin: 0;
  color: var(--primary-light);
}

p {
  text-align: justify;
}

@media (max-width: 770px) {
  /* h6 {
    margin: 0 !important;
  } */

  .pageContainer h4 {
    margin: 0.5rem 0 0;
  }

  .pageContainer {
    padding: 3rem 6% 0;
  }
}
