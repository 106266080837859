.resourceContainer {
  margin: 4rem auto auto;
  color: var(--primary-light);
  max-width: 47rem;
}

.listContainer {
  margin-bottom: auto;
}

.listContainer li {
  margin-top: 1rem;
}

.listContainer a {
  font-size: 1.3rem;
  line-height: 1.5;
  font-weight: 300;
}

.headingBox {
  opacity: 0.8;
  margin: 0;
}

.contentBox {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.contentBox ul {
  margin: auto;
}

.headingBox h4 {
  margin: 0;
}

.tableContainer {
  margin-bottom: 3rem;
}

@media (max-width: 770px) {
  .resourceContainer {
    padding: 0 6%;
  }

  .headingBox {
    padding-left: 2rem;
    text-align: start;
    margin: 0;
  }
}

@media (max-width: 500px) {
  .headingBox {
    text-align: center;
    padding-left: 1rem;
  }
}
