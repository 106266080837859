/* * {
  outline: green solid 1px;
} */

.navbar {
  background-color: var(--primary-dark);
  height: 80px;
  width: 100%;
  font-size: 1.2rem;
  position: sticky;
  opacity: 0.88;
  top: 0;
  z-index: 999;
}

.navbar-container {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  width: 100%;
  /* max-width: 1500px; */
  color: var(--primary-light);
  font-size: 1.5rem;
  letter-spacing: 2px;
  padding: 0 4.2rem;
  text-emphasis: none;
  text-decoration: none;
}

/* .navbar ul {
  margin: 0;
} */

.navbar-logo {
  color: #fff;
  /* justify-self: start; */
  /* margin-left: 20px; */
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-logo img {
  width: 3.5rem;
}

.nav-icon {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 2rem;
  list-style: none;
  text-align: center;
  align-items: center;
  justify-content: end;
  margin: 0;
  padding: 0;
  /* z-index: 100; */
}

.nav-item {
  height: 80px;
  display: flex;
  justify-content: center;
}

.nav-links {
  color: #fff !important;
  display: flex;
  align-items: center;
  text-decoration: none;
  /* padding: 0.5rem 1rem; */
  border-bottom: 2px solid transparent;
  transition: 0.2s all;
  height: 100%;
}

.nav-links a {
  color: var(--primary-light);
}

.nav-links:hover {
  margin: auto;
  color: var(--accent-color) !important;
  text-decoration: none;
  border-bottom: 2px solid var(--primary-light);
  /* transition: all 0.2s ease-out; */
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  margin-right: 2rem;
  display: none;
  transition: 0.4s all;
}

@media screen and (max-width: 900px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    margin: 0;
    transition: all 0.25s ease;
  }

  .nav-menu.active {
    padding-left: 0;
    background: var(--primary-dark);
    /* margin: 0; */
    left: 0;
    opacity: 1;
    transition: all 0.25s ease;
    z-index: 1;
    justify-content: center;
  }

  .nav-menu.active li {
    width: 90%;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    min-width: 7rem;
    display: table;
  }

  .nav-links:hover {
    background-color: var(--primary-light);
    color: #242424 !important;
    border-radius: 0;
  }

  .navbar-logo {
    color: var(--primary-light);
    justify-self: start;
    /* cursor: pointer; */
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    width: 100%;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 66%);
    font-size: 2rem;
    cursor: pointer;
  }

  .fa-times {
    color: var(--primary-light);
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: var(--primary-light);
    padding: 14px 20px;
    border: 1px solid var(--primary-light);
    transition: all 0.3s ease-out;
  }

  .navbar-container {
    justify-content: space-between;
    padding: 0;
  }

  .nav-links-mobile:hover {
    background: var(--primary-light);
    color: #242424;
    transition: 250ms;
  }
}

@media screen and (max-width: 500px) {
  .navbar-logo {
    cursor: pointer;
    text-decoration: none;
    font-size: 1.3rem;
    margin: auto;
  }

  .navbar-container {
    padding: 0;
    justify-content: center;
  }

  .navbar-logo img {
    width: 2.5rem;
  }
}
