.MainFooter {
  background-color: rgb(11, 6, 28);
  opacity: 0.88;
  color: whitesmoke;
  width: 100%;
  min-height: 80px;
  display: flex;
  flex-basis: auto;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
}
