* {
  box-sizing: border-box;
}

body {
  margin: 0;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  background-color: var(--main-bg-color);
  overscroll-behavior: none;
  overflow: auto;
}

html {
  height: -webkit-fill-available;
}

/* CSS Variables: */
:root {
  /* Colors: */
  --main-bg-color: rgb(62, 56, 78);
  --primary-dark: rgb(11, 6, 28);
  --primary-light: aliceblue;
  --accent-color: rgb(240, 184, 194);
  --accent-bold: rgb(163, 87, 103);
  --accent-dark: rgba(202, 195, 240, 0.6);
  --font-tint: rgba(245, 245, 245, 0.8);
  --font-tint-dark: rgba(62, 56, 78, 0.6);

  /* Font Weight: */
  --fw-l: 300;
  --fw-n: 450;
  --fw-m: 600;
  --fw-b: 900;

  /* Font Sizing: */
  --fs-2: 3rem;
  --fs-3: 2.5rem;
  --fs-4: 2rem;
  --fs-5: 1.5rem;
  --fs-6: 1.4rem;
  --fs-7: 1.2rem;
  --fs-p: 1rem;
}

a {
  text-decoration: none;
  border-bottom: rgba(240, 184, 194, 0.6) 0.075em solid;
}

a:link {
  color: rgb(163, 87, 103);
}

a:visited {
  color: rgb(228, 125, 147);
}

.container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}

h2 {
  font-size: var(--fs-2);
}

h3 {
  font-size: var(--fs-3);
  font-weight: var(--fw-n);
}

h4 {
  font-size: var(--fs-4);
  font-weight: var(--fw-n);
}

h5 {
  font-size: var(--fs-5);
  font-weight: var(--fw-n);
}

h6 {
  font-size: var(--fs-6);
  font-weight: var(--fw-l);
  margin: 1.5rem 0;
  /* color: var(--font-tint); */
  color: var(--accent-dark);
  opacity: 0.7;
  line-height: 1.3;
}

p {
  font-size: var(--fs-6);
  font-weight: var(--fw-l);
  margin: 1.5rem 0;
  color: var(--font-tint);
  line-height: 1.3;
}

@media (max-width: 770px) {
  h6 {
    font-size: var(--fs-7);
    line-height: 1.1;
    opacity: 0.7;
    margin: 0.5rem 0;
  }

  h3 {
    font-size: var(--fs-4);
    font-weight: var(--fw-l);
    opacity: 0.9;
    letter-spacing: 1px;
    margin: 0;
  }

  h4 {
    font-size: var(--fs-5);
    font-weight: var(--fw-l);
    opacity: 0.8;
    margin: 0;
  }

  p {
    /* font-size: var(--fs-6); */
    font-size: 1rem;
    font-weight: 200;
    margin: 1.5rem 0;
    color: var(--font-tint);
    line-height: 1.2;
    opacity: 0.9;
  }
}
