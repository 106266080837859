.main {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 2rem 7rem;
  min-height: 76vh;
  max-width: 100rem;
  width: 100%;
}

.loadingScreen {
  min-height: calc(100vh - 10rem);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: whitesmoke;
}

.sampleCharts {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 47%;
  /* max-width: 500px; */
  margin: 0.75rem auto;
}

@media (max-width: 500px) {
  .main {
    padding: 0px;
  }

  .sampleCharts {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: auto;
  }
}

@media (max-width: 1200px) {
  .main {
    padding: 0.5rem 5rem;
  }
}

@media (max-width: 1000px) {
  .main {
    padding: 0.5rem 2rem;
  }
}

@media (min-width: 450px) and (max-width: 900px) {
  .main {
    padding: 0.5rem 1rem;
  }

  .sampleCharts {
    margin: 0 0.25rem 0 1rem;
  }
}

@media (max-width: 700px) {
  .main {
    padding: 0 0 0 0;
  }
  .sampleCharts {
    width: 95%;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
  }
}

@media (max-width: 400px) {
  .main {
    padding: 0;
  }

  .sampleCharts {
    flex-direction: column;
  }
}

/* @media (max-width: 400px) {
  .sampleCharts {
    flex-direction: column;
  }
} */
