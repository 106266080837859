.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 47%;
  /* max-width: 500px; */
  margin: 0.7rem auto;
  background-color: white;
  border-radius: 0.5rem;
}

.chartContainer {
  padding: 0 1rem 1rem 1rem;
  border-radius: 0.5rem;
}

.inputBar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
  text-align: center;
}

.headerContainer {
  display: flex;
  flex-direction: column;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  justify-content: center;
  top: 0;
  width: 100%;
  height: 3rem;
  background-color: rgba(202, 195, 240, 0.6);
  color: var(--primary-dark);
  margin-bottom: 1rem;
  border: 1px var(--primary-light);
}

.inputBar h5 {
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 1.6;
  margin: 0;
}

.map {
  /* height: 60vh; */
  height: auto;
  width: 76%;
  margin: 0.7rem auto;
}

.dropdownContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

h1 {
  margin: 0 0 0 1rem;
}

@media (max-width: 900px) {
  .container {
    margin: 0.25rem;
    border-radius: 0.25rem;
    padding: 0.75rem 0.25rem 0.25rem 0.25rem;
    font-size: small;
  }

  .sample {
    width: 100%;
    height: 10rem;
    justify-content: flex-start;
  }

  .map {
    height: 45vh;
  }

  .inputBar {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
  }

  .inputBar h5 {
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 1.2rem;
    line-height: 1.6;
  }

  .headerContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    background-color: white;
    margin: 0;
    padding-left: 1.5rem;
  }
}

@media (max-width: 900px) and (min-width: 700px) {
  .container:nth-child(odd) {
    margin-left: 1rem;
  }

  .headerContainer {
    padding-left: 1rem;
  }
}

@media (max-width: 800px) {
  .inputBar h5 {
    font-size: 1.1rem;
  }
}

/* @media (max-width: 600px) {
  .inputBar h5 {
    font-size: 1rem;
  }
} */

@media (max-width: 700px) {
  .container {
    width: 95%;
    /* margin: 0.25rem 0.25rem 0.25rem 0.5rem; */
    margin: 0.25rem auto;
  }

  .map {
    height: 45vh;
  }

  .inputBar {
    margin: 0.5rem 0;
  }
}
