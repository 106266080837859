:root {
  --cases-color: #0c5daa;
  --deaths-color: #820014;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 300px;
  background-color: white;
  border-radius: 0.5rem;
  width: 100%;
}

.headerContainer {
  display: flex;
  flex-direction: column;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  justify-content: center;
  top: 0;
  width: 100%;
  height: 3rem;
  background-color: rgba(202, 195, 240, 0.6);
  /* color: rgb(51, 50, 50); */
  color: var(--primary-dark);

  /* margin-bottom: 1rem; */
  border: 1px whitesmoke;
}

.sample {
  /* width: 100%; */
  height: 47%;
  justify-content: flex-start;
}

.cases {
  color: var(--cases-color);
}

.deaths {
  color: var(--deaths-color);
}

.chartContainer {
  padding: 0 1rem 1rem 1rem;
  border-radius: 0.5rem;
}

.sampleContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6em;
  height: 100%;
  /* padding-bottom: 1rem; */
}

.sampleContainer h1,
h2 {
  font-family: 'Times New Roman', Times, serif;
}

.sampleContainer h5 {
  margin-left: 2rem;
}

.inputBar {
  /* padding-bottom: 0.5rem; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  /* margin-bottom: 1rem; */
  text-align: center;
}

.inputBar h5 {
  /* text-transform: capitalize; */
  font-size: 1.5rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 1.6;
  margin: 0;
}

.footerContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 3rem;
}

.footerContent h6 {
  margin: 0.12rem;
  color: var(--font-tint-dark);
}

@media (max-width: 900px) {
  .headerContainer {
    flex-direction: row;
    background-color: white;
    margin: 0.5rem 0 0 0;
  }

  .headerContainer h5 {
    margin-bottom: 4px;
  }

  .sample {
    /* height: 48.75%; */
    height: 95%;
    /* width: 49.2%; */
    margin: 0.25rem 0;
    border-radius: 0.25rem;
  }

  .sampleContainer h1 {
    font-size: 2.5rem;
  }

  .footerContent {
    margin-left: 1rem;
  }
}

@media (max-width: 900px) and (min-width: 650px) {
  .headerContainer {
    align-items: flex-end;
  }
}

@media (max-width: 500px) {
  /* .sample {
    width: 100%;
    justify-content: flex-start;
  } */

  .container {
    width: 100%;
    /* margin: 0.25rem auto; */
    font-size: small;
  }

  .headerContainer h5 {
    font-size: 1.1rem;
  }

  .sampleContainer {
    flex-direction: column;
    font-size: 1.6em;
  }
}

@media (max-width: 700px) and (min-width: 400px) {
  .sample {
    width: 49.2%;
  }
}

@media (max-width: 400px) {
  .container {
    min-height: 8rem;
    justify-content: space-evenly;
  }

  .sampleContainer {
    flex-direction: row;
  }

  .footerContent {
    margin-left: 1rem;
  }

  .sample {
    width: 100%;
    /* height: 8rem; */
    justify-content: flex-start;
  }
}
