@import url('./DynamicChart.module.css');

h2,
h3 {
  margin: 10px;
  font-size: 1.2em;
}

h3 {
  font-size: 1em;
}

p {
  font-size: 0.85em;
  margin: 10px;
  text-align: left;
}

/**
* Create a position for the map
* on the page */
#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 47%;
  max-width: 500px;
  margin: 0.7rem auto;
  background-color: white;
  border-radius: 0.5rem;

  /* height: 60vh; */
  height: auto;
  width: 76%;
  margin: 0.7rem auto;
}

/**
* Set rules for how the map overlays
* (information box and legend) will be displayed
* on the page. */
.map-overlay {
  position: absolute;
  top: 1em;
  left: 1em;
  background: rgba(255, 255, 255, 0.8);
  margin-right: 20px;
  font-family: Arial, sans-serif;
  overflow: auto;
  border-radius: 3px;
}

.button-box {
  position: absolute;
  top: 1em;
  right: 1em;
  display: flex;
  flex-direction: column;
}

.zoom-btn {
  background: rgba(255, 255, 255, 0.8);
  font-size: medium;
  border: none;
  cursor: auto;
  outline: none;
  width: 25px;
  height: 25px;
  margin: 2px;
  border-radius: 3px;
}

#legend {
  padding: 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  line-height: 18px;
  height: 15rem;
  width: 8rem;
}

#legend-mobile {
  padding: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  line-height: 12px;
  height: 11rem;
  width: 4.8rem;
}

#legend-mobile h5 h {
  font-size: 0.7rem;
}

.legend-key {
  display: inline-block;
  border-radius: 20%;
  width: 10px;
  height: 10px;
  margin-right: 5px;
}

@media (max-width: 600px) {
  .map-overlay {
    top: 0.3em;
    left: 0.3em;
  }

  .button-box {
    top: 0.3em;
    right: 0.3em;
  }
}
