.Footer {
  font-size: larger;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 0.7em;
  background-color: white;
  color: rgb(15, 15, 66);
}

.content-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer-content {
  margin: auto;
}

a:visited {
  color: rgba(173, 216, 230, 1);
}

@media (max-width: 500px) {
  .Footer {
    font-size: small;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    padding: 1em;
  }
}
